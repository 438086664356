import React, { Component } from 'react';

export default class NotFound extends Component {
    render() {
      return (
        <React.Fragment>
            <h1>Error 404</h1>
            <p>Sorry, this page does not exist (anymore).</p>
        </React.Fragment>
    );
  }
}
